<template>
  <div>
    <Header />
    <v-container>
      <v-card>
        <v-card-title>
        Versandprofilregeln verwalten
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="openCreateDialog"
        >
          <v-icon left>mdi-plus</v-icon>
          Neue Regel erstellen
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          Hier können Sie spezielle Regeln für die Versandprofilauswahl definieren. Regeln werden nach Priorität (1 = höchste) angewendet und haben Vorrang vor der Standardauswahl.
        </p>
        
        <v-data-table
          :headers="headers"
          :items="rules"
          :loading="loading"
          class="elevation-1"
          sort-by="priority"
        >
          <template v-slot:item.validFrom="{ item }">
            {{ formatDate(item.validFrom) }}
          </template>
          
          <template v-slot:item.validTo="{ item }">
            {{ formatDate(item.validTo) }}
          </template>
          
          <template v-slot:item.active="{ item }">
            <v-chip
              :color="item.active ? 'success' : 'error'"
              small
            >
              {{ item.active ? 'Aktiv' : 'Inaktiv' }}
            </v-chip>
          </template>
          
          <template v-slot:item.profileId="{ item }">
            <v-chip
              color="primary"
              small
            >
              {{ getProfileName(item.profileId) }}
            </v-chip>
          </template>
          
          <template v-slot:item.actions="{ item }">
            <v-btn
              small
              icon
              color="primary"
              @click="editRule(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              small
              icon
              color="red"
              @click="confirmDeleteRule(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    
    <!-- Dialog für Erstellung/Bearbeitung einer Regel -->
    <v-dialog
      v-model="dialog"
      max-width="800"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ editedItem._id ? 'Regel bearbeiten' : 'Neue Regel erstellen' }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editedItem.name"
                  label="Regelname"
                  :rules="[rules => !!rules || 'Name ist erforderlich']"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editedItem.description"
                  label="Beschreibung"
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" sm="4">
                <v-menu
                  ref="validFromMenu"
                  v-model="validFromMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="validFromFormatted"
                      label="Gültig ab"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="validFromDate"
                    @input="validFromMenu = false"
                    locale="de"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              
              <v-col cols="12" sm="4">
                <v-menu
                  ref="validToMenu"
                  v-model="validToMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="validToFormatted"
                      label="Gültig bis"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="validToDate"
                    @input="validToMenu = false"
                    locale="de"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              
              <v-col cols="12" sm="4">
                <v-switch
                  v-model="editedItem.active"
                  label="Aktiv"
                  color="success"
                ></v-switch>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.profileId"
                  :items="shippingProfiles"
                  item-text="name"
                  item-value="id"
                  label="Zu verwendendes Versandprofil"
                  :rules="[v => !!v || 'Versandprofil ist erforderlich']"
                  required
                ></v-select>
              </v-col>
            </v-row>
            
            <v-divider class="my-4"></v-divider>
            <h3>Bedingungen</h3>
            <p class="subtitle-2 grey--text">Alle Bedingungen müssen erfüllt sein, damit die Regel angewendet wird</p>
            
            <div v-for="(condition, index) in editedItem.conditions" :key="index" class="mb-3">
              <v-row>
                <v-col cols="12" sm="3">
                  <v-select
                    v-model="condition.type"
                    :items="conditionTypes"
                    item-text="text"
                    item-value="value"
                    label="Bedingungstyp"
                  ></v-select>
                </v-col>
                
                <v-col cols="12" sm="2" v-if="shouldShowOperator(condition.type)">
                  <v-select
                    v-model="condition.operator"
                    :items="operatorItems"
                    label="Operator"
                  ></v-select>
                </v-col>
                
                <v-col :cols="12" :sm="shouldShowOperator(condition.type) ? 5 : 7">
                  <v-text-field
                    v-if="condition.type === 'zipCode'"
                    v-model="condition.value"
                    label="Postleitzahlengebiet (z.B. '1,2,3')"
                  ></v-text-field>
                  
                  <v-select
                    v-else-if="condition.type === 'shop'"
                    v-model="condition.value"
                    :items="shopItems"
                    label="Marketplace"
                  ></v-select>
                  
                  <v-select
                    v-else-if="condition.type === 'national'"
                    v-model="condition.value"
                    :items="[{text: 'National', value: 'true'}, {text: 'International', value: 'false'}]"
                    item-text="text"
                    item-value="value"
                    label="Versandregion"
                  ></v-select>
                  
                  <v-select
                    v-else-if="condition.type === 'prime'"
                    v-model="condition.value"
                    :items="[{text: 'Prime', value: 'true'}, {text: 'Nicht Prime', value: 'false'}]"
                    item-text="text"
                    item-value="value"
                    label="Versandklasse"
                  ></v-select>
                  
                  <v-text-field
                    v-else-if="condition.type === 'weight'"
                    v-model="condition.value"
                    label="Gewicht (g)"
                    type="number"
                  ></v-text-field>
                  
                  <v-text-field
                    v-else-if="condition.type === 'price'"
                    v-model="condition.value"
                    label="Bestellwert (€)"
                    type="number"
                  ></v-text-field>
                  
                  <v-text-field
                    v-else-if="condition.type === 'sku'"
                    v-model="condition.value"
                    label="SKU"
                  ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="2">
                  <v-btn
                    color="error"
                    icon
                    @click="removeCondition(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            
            <v-btn
              color="primary"
              text
              @click="addCondition"
            >
              <v-icon left>mdi-plus</v-icon>
              Bedingung hinzufügen
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            @click="closeDialog"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="primary"
            @click="saveRule"
            :disabled="!valid || editedItem.conditions.length === 0"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- Bestätigungsdialog für Löschen -->
    <v-dialog
      v-model="deleteDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Regel löschen?
        </v-card-title>
        <v-card-text>
          Sind Sie sicher, dass Sie die Regel "{{ deleteItem.name }}" löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            @click="deleteDialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="red"
            text
            @click="deleteRule"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- Snackbar für Feedback -->
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="3000"
    >
      {{ snackbarText }}
      
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
  </div>
</template>

<script>
import ShippingRulesService from '@/services/ShippingRulesService';
import Header from './header';
export default {
  name: 'ShippingRulesManager',
  components: {
    Header
  },
  
  data() {
    return {
      loading: false,
      rules: [],
      dialog: false,
      deleteDialog: false,
      valid: false,
      
      editedItem: {
        name: '',
        description: '',
        profileId: null,
        active: true,
        conditions: [],
      },
      
      deleteItem: {},
      
      validFromMenu: false,
      validToMenu: false,
      validFromDate: null,
      validToDate: null,
      
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Beschreibung', value: 'description' },
        { text: 'Gültig von', value: 'validFrom' },
        { text: 'Gültig bis', value: 'validTo' },
        { text: 'Status', value: 'active' },
        { text: 'Versandprofil', value: 'profileId' },
        { text: 'Aktionen', value: 'actions', sortable: false }
      ],
      
      conditionTypes: [
        { text: 'Postleitzahlgebiet', value: 'zipCode' },
        { text: 'Marketplace', value: 'shop' },
        { text: 'Versandregion', value: 'national' },
        { text: 'Prime-Status', value: 'prime' },
        { text: 'Gewicht', value: 'weight' },
        { text: 'Bestellwert', value: 'price' },
        { text: 'SKU enthält', value: 'sku' },
      ],
      
      operatorItems: [
        { text: 'Kleiner als', value: 'lt' },
        { text: 'Größer als', value: 'gt' },
      ],
      
      shopItems: [
        'amazon',
        'shopify',
        'etsy',
        'acs'
      ],
      
      shippingProfiles: [
        { id: 6, name: 'DHL Paket' },
        { id: 7, name: 'Internetmarke Kompaktbrief' },
        { id: 8, name: 'Internetmarke Großbrief' },
        { id: 9, name: 'Internetmarke Maxibrief' },
        { id: 24, name: 'DHL Warenpost' },
        { id: 27, name: 'Express' },
        { id: 28, name: 'DPD Classic' },
        { id: 29, name: 'DPD PARCELLetter' },
        { id: 32, name: 'DPD Lyto Classic' },
        { id: 33, name: 'DPD Lyto PARCELLetter' },
        { id: 34, name: 'Benchmarkets' },
      ],
      
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
    };
  },
  
  computed: {
    validFromFormatted: {
      get() {
        return this.validFromDate ? this.formatDateToGerman(this.validFromDate) : '';
      },
      set(val) {
        this.validFromDate = val;
      }
    },
    
    validToFormatted: {
      get() {
        return this.validToDate ? this.formatDateToGerman(this.validToDate) : '';
      },
      set(val) {
        this.validToDate = val;
      }
    },
  },
  
  mounted() {
    this.initialize();
  },
  
  methods: {
    initialize() {
      this.loading = true;
      
      ShippingRulesService.getAllRules()
        .then(response => {
          this.rules = response.data;
        })
        .catch(error => {
          console.error('Fehler beim Laden der Regeln:', error);
          this.showSnackbar('Fehler beim Laden der Regeln', 'error');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    
    formatDate(dateStr) {
      if (!dateStr) return '-';
      
      const date = new Date(dateStr);
      return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
    },
    
    formatDateToGerman(dateStr) {
      const date = new Date(dateStr);
      return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
    },
    
    parseDateFromGerman(dateStr) {
      const [day, month, year] = dateStr.split('.');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    
    getProfileName(profileId) {
      const profile = this.shippingProfiles.find(p => p.id === profileId);
      return profile ? profile.name : profileId;
    },
    
    shouldShowOperator(type) {
      return ['weight', 'price'].includes(type);
    },
    
    openCreateDialog() {
      this.editedItem = {
        name: '',
        description: '',
        profileId: null,
        active: true,
        conditions: [],
      };
      this.validFromDate = new Date().toISOString().substr(0, 10);
      this.validToDate = new Date(new Date().setDate(new Date().getDate() + 14)).toISOString().substr(0, 10);
      
      this.dialog = true;
    },
    
    editRule(item) {
      this.editedItem = JSON.parse(JSON.stringify(item));
      
      // Set the date values for the date pickers
      if (item.validFrom) {
        this.validFromDate = new Date(item.validFrom).toISOString().substr(0, 10);
      }
      
      if (item.validTo) {
        this.validToDate = new Date(item.validTo).toISOString().substr(0, 10);
      }
      
      this.dialog = true;
    },
    
    closeDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = {
          name: '',
          description: '',
          profileId: null,
          active: true,
          conditions: [],
        };
        this.validFromDate = null;
        this.validToDate = null;
      });
    },
    
    addCondition() {
      this.editedItem.conditions.push({
        type: 'zipCode',
        value: '',
      });
    },
    
    removeCondition(index) {
      this.editedItem.conditions.splice(index, 1);
    },
    
    saveRule() {
      if (!this.$refs.form.validate()) return;
      
      // Update dates from the date picker values
      const updatedRule = { ...this.editedItem };
      
      if (this.validFromDate) {
        updatedRule.validFrom = new Date(this.validFromDate);
      }
      
      if (this.validToDate) {
        updatedRule.validTo = new Date(this.validToDate);
      }
      
      this.loading = true;
      
      if (updatedRule._id) {
        // Update existing rule
        const id = updatedRule._id;
        delete updatedRule._id; // Remove ID from the request body
        
        ShippingRulesService.updateRule(id, updatedRule)
          .then(response => {
            const index = this.rules.findIndex(rule => rule._id === id);
            if (index !== -1) {
              this.rules.splice(index, 1, response.data);
            }
            this.showSnackbar('Regel erfolgreich aktualisiert');
            this.closeDialog();
          })
          .catch(error => {
            console.error('Fehler beim Aktualisieren der Regel:', error);
            this.showSnackbar('Fehler beim Aktualisieren der Regel', 'error');
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        // Create new rule
        ShippingRulesService.createRule(updatedRule)
          .then(response => {
            this.rules.push(response.data);
            this.showSnackbar('Regel erfolgreich erstellt');
            this.closeDialog();
          })
          .catch(error => {
            console.error('Fehler beim Erstellen der Regel:', error);
            this.showSnackbar('Fehler beim Erstellen der Regel', 'error');
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    
    confirmDeleteRule(item) {
      this.deleteItem = item;
      this.deleteDialog = true;
    },
    
    deleteRule() {
      this.loading = true;
      
      ShippingRulesService.deleteRule(this.deleteItem._id)
        .then(() => {
          const index = this.rules.findIndex(rule => rule._id === this.deleteItem._id);
          if (index !== -1) {
            this.rules.splice(index, 1);
          }
          this.showSnackbar('Regel erfolgreich gelöscht');
        })
        .catch(error => {
          console.error('Fehler beim Löschen der Regel:', error);
          this.showSnackbar('Fehler beim Löschen der Regel', 'error');
        })
        .finally(() => {
          this.deleteDialog = false;
          this.loading = false;
        });
    },
    
    showSnackbar(text, color = 'success') {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
.subtitle-2 {
  margin-bottom: 16px;
}
</style>